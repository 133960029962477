import api from '@/base/utils/request';
//获取选择模板列表
export const getTemplates = data => {
  return api({
    url: '/admin/cyc/invite_members_tmpl/index',
    method: 'post',
    data
  });
};
//模板内容—列表
export const setIndex = data => {
  return api({
    url: '/admin/cyc/invite_members_tmpl_content/index',
    method: 'post',
    data
  });
};
//模板内容—保存
export const getSave = data => {
  return api({
    url: '/admin/cyc/invite_members_tmpl_content/save',
    method: 'post',
    data
  });
};
//删除
export const getDel = data => {
  return api({
    url: '/admin/cyc/invite_members_tmpl_content/del',
    method: 'post',
    data
  });
};
//详情
export const getDetail = data => {
  return api({
    url: '/admin/cyc/invite_members_tmpl_content/detail',
    method: 'post',
    data
  });
};
//显示
export const updateStatus = data => {
  return api({
    url: '/admin/cyc/invite_members_tmpl_content/updateStatus',
    method: 'post',
    data
  });
};

export const saveInviteTemplates = (data) => {
  return api({
    url: '/admin/cyc/invite_members_tmpl/save',
    method: 'post',
    data
  })
}
