import api from '@/base/utils/request';

// 列表数据
export const indexList = data => {
  return api({
    url: '/admin/cyc/banner/index',
    method: 'post',
    data
  });
};
// 位置类型
export const positionType = data => {
  return api({
    url: '/admin/cyc/banner/positionType',
    method: 'post',
    data
  });
};
// 排序
export const updateSort = data => {
  return api({
    url: '/admin/cyc/banner/updateSort',
    method: 'post',
    data
  });
};
// 显示
export const isDisplay = data => {
  return api({
    url: '/admin/cyc/banner/updateIsShow',
    method: 'post',
    data
  });
};
// 软删除
export const softDelete = data => {
  return api({
    url: '/admin/cyc/banner/softDelete',
    method: 'post',
    data
  });
};
// 硬删除
export const deLs = data => {
  return api({
    url: '/admin/cyc/banner/del',
    method: 'post',
    data
  });
};
//还原
export const putBack = data => {
  return api({
    url: '/admin/cyc/banner/putBack',
    method: 'post',
    data
  });
};