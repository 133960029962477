<template>
  <div class="confirm-content">
    <el-button class="add-member" type="primary" @click="addMember" size="small"
      >新增</el-button
    >
    <el-button class="add-member" size="small" type="danger" @click="handleDel"
      >删除</el-button
    >
    <el-table
      v-loading="loading"
      :data="memberList"
      class="thead-light"
      stripe
      style="width: 100%"
      @selection-change="handleSelectionChange"
  
    >
      <el-empty slot="empty" description="文案内容未配置" />
      <!-- 勾选 -->
      <el-table-column fixed type="selection" width="55"></el-table-column>
      <!-- 序号 -->
      <el-table-column width="70" type="index" label="序号"></el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" :width="120" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editRow(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="DeleteRow(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        v-for="(th, index) in thead"
        :key="th.prop"
        :prop="th.prop"
        :label="th.label"
        :min-width="th.minWidth || ''"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-if="th.prop === 'status'"
            v-model="scope.row[th.prop]"
            @change="isOpen($event, scope.row.id)"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
          <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible="showresults"
      class="dialog-vertical"
      width="400px"
      :title="tmplcontent.id == '' ? '新增' : '编辑'"
      @close="close('tmplcontent')"
    >
      <el-form
        size="medium"
        label-position="top"
        label-width="70px"
        :model="tmplcontent"
        :rules="rules"
        ref="tmplcontent"
        @submit.native.prevent
      >
        <el-form-item label="邀请文案：" prop="content">
          <el-input
            type="textarea"
            :rows="4"
            resize="none"
            v-model="tmplcontent.content"
            :maxlength="60"
            show-word-limit
            placeholder="请输入邀请文案"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="close('tmplcontent')">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="confirm('tmplcontent')"
          :loading="loading"
          >确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { setIndex, getDel, getSave, updateStatus } from '../api/template'
import { isDisplay } from '../../advert/api/advert-list'

export default {
  props: {
    tmpl_id: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      showresults: false,
      thead: [
        { label: 'ID', prop: 'id', minWidth: 90 },
        { label: '文案内容', prop: 'content', minWidth: 200 },
        { label: '启用', prop: 'status', minWidth: 50 },
      ],
      rules: {
        content: [
          { required: true, message: '请输入邀请文案', trigger: 'blur' },
        ],
      },
      selectArr: [],
      memberList: [],
      pageData: {},
      tmplcontent: {
        tmpl_id: '',
        id: '',
        content: '',
      },
    }
  },
  watch: {
    tmpl_id(val) {
      this.tmplcontent.tmpl_id = val
      this.getTesterList()
    },
  },
  methods: {
    isOpen(e, id) {
      this.loading = true
      updateStatus({ id: id, status: e })
        .then((res) => {
          this.$message.success(res.msg)
          this.getTesterList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    editRow(e) {
      this.showresults = true
      this.tmplcontent.id = e.id
      this.tmplcontent.content = e.content
    },
    close(formName) {
      this.$refs[formName].resetFields()
      this.showresults = false
      this.tmplcontent.content = ''
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          getSave({
            ...this.tmplcontent,
            tmpl_id: this.tmpl_id,
          })
            .then((res) => {
              this.$message.success(res.msg)
              this.getTesterList()
              this.showresults = false
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    // 获取体验成员列表
    getTesterList() {
      this.loading = true
      setIndex({ tmpl_id: this.tmplcontent.tmpl_id })
        .then((res) => {
          const { data } = res
          this.memberList = data
          this.pageData = data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 点击添加
    addMember() {
      this.showresults = true
      this.tmplcontent.id = ''
      this.tmplcontent.content = ''
    },
    // 批量移除
    handleDel() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm('确定要删除选中数据吗？删除后无法恢复。', '提示', {
            type: 'error',
          })
          .then((res) => {
            this.loading = true
            getDel({ ids: [...this.selectArr] })
              .then((res) => {
                this.$message.success(res.msg)
                this.getTesterList()
              })
              .catch((err) => {
                this.loading = false
              })
          })
          .catch((err) => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击移除
    DeleteRow(id) {
      this.$msgbox
        .confirm('确定要删除该数据吗？删除后无法恢复。', '提示', {
          type: 'error',
        })
        .then((res) => {
          this.loading = true
          getDel({ ids: [id] })
            .then((res) => {
              this.$message.success(res.msg)
              this.getTesterList()
            })
            .catch((err) => {
              this.loading = false
            })
        })
        .catch((err) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm-content {
  margin-left: 11px;
  ::v-deep.el-textarea__inner {
    // height: 160px;
    width: 100%;
  }
}
.add-member {
  margin: 0 10px 20px;
}
</style>
