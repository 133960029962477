<template>
  <div>
    <div class="modular" v-loading="loading">
      <p class="modular-title">模版设置</p>
      <div class="modular-content">
        <el-form ref="form" label-width="80px" class="medium-form">
          <el-form-item label="选择模版：">
            <el-button :class="['template-btn',current_id === item.code?'active':'']" size="small"
              v-for="(item, index) in TemplateOptions" :key="item.code" @click="current_id = item.code">
              {{item.name}}</el-button>
          </el-form-item>
          <template v-for="(tmp, tmpI) in TemplateOptions">
            <el-form-item v-if="tmp.config && tmpI > 0" v-show="current_id === tmp.code" label="组织logo：" :key="tmp.id">
              <single-media-wall ratio="110:40" directly v-model="tmp.config.logo"
                                 :width="220" :height="80" add-text=""
                                 :border-radius="4"
                                 @handleMediaData="saveTemplates" />
              <p class="info">
                <i class="el-icon el-icon-info" /><span>建议尺寸 110 * 40</span>
              </p>
            </el-form-item>
          </template>
          <el-form-item v-if="templateConfigData" label="分享跳转：" required>
            <el-select v-model="templateConfigData.config.jump_type" @change="saveTemplates">
              <el-option v-for="(item) in [{
                id: 0,
                name: '入会申请页',
              }, {
                id: 1,
                name: '首页'
              }]" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="模版预览：">
            <div class="preview-box">
              <el-image class="preview-img cursor-pointer" v-for="(tmp, tmpI) in TemplateOptions"
                @click="$previewImage([tmp.preview_image])" v-show="tmp.code === current_id" :key="tmp.code"
                :src="fixImageUrl(tmp.preview_image)" fit="contain" />
<!--              <div class="preview-handle" v-if="previewImages.length > 1">-->
<!--                <el-image @click="handleLastOne" class="switch-btn cursor-pointer"-->
<!--                  :src="require('../assets/images/circle-left.png')" fit="cover">-->
<!--                </el-image>-->
<!--                <p>{{image_index + 1+'/'+previewImages.length}}</p>-->
<!--                <el-image @click="handleNextOne" class="switch-btn cursor-pointer"-->
<!--                  :src="require('../assets/images/circle-right.png')" fit="cover">-->
<!--                </el-image>-->
<!--              </div>-->
            </div>
          </el-form-item>
        </el-form>
        <div class="modular">
          <p class="modular-title">邀请文案配置</p>
          <div class="modular-content">
            <reminder text="会员每次打开海报模版时会随机显示已启用的邀请文案数据" />
          </div>
          <member-list v-if="current_id" :tmpl_id="TemplateOptions.find(el => el.code === current_id).id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberList from "../components/MemberList.vue";
import {getTemplates, saveInviteTemplates} from "../api/template";
import Reminder from "../../common/components/Reminder.vue";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
export default {
  components: {SingleMediaWall, MemberList, Reminder },
  data() {
    return {
      loading: false,
      region: 0,
      TemplateOptions: [],
      previewImages: [
        window.serverConfig.VUE_APP_ADMINURL +
          "/cyc/images/admin/invite.png",
      ],
      image_index: 0,
      current_id: '',
      templateConfigData: null
    };
  },
  created() {
    this.getTemplateOptions();
  },
  methods: {
    getTemplateOptions() {
      this.loading = true;
      getTemplates()
        .then((res) => {
          this.templateConfigData = res.data;
          this.TemplateOptions = res.data.tmpls;
          this.current_id = this.TemplateOptions[0].code;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //上一张
    handleLastOne() {
      if (this.image_index > 0) {
        this.image_index--;
      }
    },
    // 下一张
    handleNextOne() {
      if (this.image_index < this.previewImages.length - 1) {
        this.image_index++;
      }
    },
    saveTemplates() {
      this.loading = true
      saveInviteTemplates({
        config: this.templateConfigData.config,
        tmpls: this.TemplateOptions
      }).then(res => {
        this.$message.success(res.msg)
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin-left: 11px;
  background: #ebf1ff;
  border: 1px solid #d7e4ff;
  margin-bottom: 28px;
  border-radius: 2px;
}
.main-title {
  color: #3576ff;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.main-context {
  font-size: 14px;
  line-height: 24px;
  ::v-deep.el-col-23 {
    display: flex;
    margin-left: 30px;
    padding: 5px 0 5px 0;
    span {
      line-height: 10px;
      padding-right: 10px;
    }
  }
}
.el-form-item {
  margin-left: 11px;
}

.template-btn {
  &.active {
    background-color: #ffffff;
    color: #3576ff;
    border: 1px solid #3576ff;
  }
}
.preview-box {
  display: flex;
  flex-direction: column;
  .preview-img {
    width: 240px;
    height: 240px;
    border-radius: 4px;
    background-color: #f5f6f7;
  }
  .preview-handle {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 21px;
    .switch-btn {
      width: 20px;
      height: 20px;
    }
    p {
      margin: 0 15px;
      font-size: 14px;
      color: #3a3a3a;
      line-height: 14px;
    }
  }
}
</style>
